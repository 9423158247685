import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Warmup: Snatch Balance 3×4`}</p>
    <p>{`then,`}</p>
    <p>{`Overhead Squat 4×6\\@75% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15-Power Snatch (115/75)(RX+ 135/95)`}</p>
    <p>{`15-Bar Facing Burpees`}</p>
    <p>{`12-Squat Snatch`}</p>
    <p>{`12-Bar Facing Burpees`}</p>
    <p>{`9-OHS`}</p>
    <p>{`9-Bar Facing Burpees`}</p>
    <p>{`for time.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      